import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './routes/Home'
import Join from './routes/Join'
import Schedule from './routes/Schedule'
import Goals from './routes/Goals'
import { AnimatePresence } from 'framer-motion';

const routes = [
  { path: '/', name: 'Home', Component: Home },
  { path: '/join', name: 'Join', Component: Join },
  { path: '/schedule', name: 'Schedule', Component: Schedule },
  { path: '/goals', name: 'Goals', Component: Goals }
]

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
