import React, {useEffect, useState} from 'react'
import AnimatedPage from '../components/AnimatedPage'
import axios from 'axios'

export default function Live() {

    let ipURL = "https://hutils.loxal.net/whois"
    let ipAddr = ""

    axios.get(ipURL).then(res=>{
        ipAddr = res.data
    })

    useEffect(() => {
        document.title = "MGP | LIVE"
    })

    const [showErr, setShowErr] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [racerName, setRacerName] = useState('')

    function sendIt() {
        let first = (document.getElementById('first-name').value)
        let last = (document.getElementById('last-name').value)

        if(first.length == 0 && last.length == 0) return

        const enrollBtn = document.getElementById('enrollBtn')
        enrollBtn.disabled = true

        setShowErr(false)

        fetch('https://mgp-forwarder.herokuapp.com/forward', {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ firstName: first, lastName: last, ip: ipAddr })
        }).then( res => {            
            setShowConfirm(res.status == 200)
            setShowErr(res.status == 500)

            if(res.status == 200) {
                let d = res.json().then(data => {
                    setRacerName(data.name)
                })
            }
            else if(res.status == 500) {
                enrollBtn.disabled = false
            }

        }).catch( err => {
            console.log('err', err.message)
        })
    }

    function resetIt() {
        setShowConfirm(false)
        setShowErr(false)
        setRacerName("")
        document.getElementById('first-name').value = ""
        document.getElementById('last-name').value = ""
        document.getElementById('enrollBtn').disabled = false
    }

    return (
        <AnimatedPage className='container mx-auto my-8'>
            <section className='my-8 mx-auto text-center px-4'>
                <div className='text-6xl font-black mb-10'>JOIN THE RACE</div>
                <div className={`${showConfirm ? '' : 'hidden'}`}>
                    <h1 className='text-xl md:text-2xl'>YOU ARE ENROLLED!</h1>
                    <h2 className='text-2xl md:text-4xl mt-4'>Your name will be <span className='text-mgpRed font-bold'>{racerName}</span></h2>
                    <button className="shadow bg-mgpRed hover:bg-red-400 focus:shadow-outline focus:outline-none mt-4 text-mgpWhite font-bold py-2 px-4 rounded" type="button" onClick={resetIt}>
                        ADD A DIFFERENT RACER
                    </button>
                </div>
                <form className={`w-full max-w-sm mx-auto ${showConfirm ? 'hidden' : ''}`}>
                    <div className="mb-4">
                        <label className="block text-mgpRed font-bold mb-2">
                            Twitch Username
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-mgpDarkGrey leading-tight focus:outline-none focus:shadow-outline" id="first-name" type="text" required />
                    </div>
                    <div className="mb-4 invisible">
                        <label className="block text-mgpRed font-bold mb-2">
                            LAST NAME
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-mgpDarkGrey leading-tight focus:outline-none focus:shadow-outline" id="last-name" type="text" required />
                    </div>
                    <div className="md:flex md:items-center">
                        <div className="w-full">
                            <button className="shadow bg-mgpRed disabled:bg-opacity-30 hover:bg-red-400 focus:shadow-outline focus:outline-none w-full text-mgpWhite font-bold py-2 px-4 rounded" type="button" onClick={sendIt} id='enrollBtn'>
                                ENROLL
                            </button>
                        </div>
                    </div>
                </form>
                <h3 className={`text-red-500 ${showErr ? '' : 'hidden'}`} id='errMessage'>There was a problem enrolling you. Try again.</h3>
            </section>
        </AnimatedPage>
    )
}
