import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import { auth } from '../firebase'
import { onAuthStateChanged } from 'firebase/auth'

const Template = () => {

    const [user, setUser] = useState({})

    onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser)
    })

    return (
        <div className='App divide-y divide-mgpDarkGrey flex flex-col justify-between h-screen'>
            <Navbar className='flex-row' />
            <div className='flex-row grow mt-20 md:mt-32'>
                <Outlet />
            </div>
            <Footer className='flex-row' />
        </div>
    )
}

export default Template
