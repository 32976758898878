// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDPbZnY9Pn6LQhJnSFuWp3OJXgE7hawnhM",
  authDomain: "marblesgrandprix.firebaseapp.com",
  projectId: "marblesgrandprix",
  storageBucket: "marblesgrandprix.appspot.com",
  messagingSenderId: "886060892592",
  appId: "1:886060892592:web:6aebc50587c8783b0c1675",
  measurementId: "G-EDF5QSESFM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
// const analytics = getAnalytics(app);

