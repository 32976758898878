import React from 'react';
import './index.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './routes/Home'
import Join from './routes/Join'
import Schedule from './routes/Schedule'
import Goals from './routes/Goals'
import Live from './routes/Live'
import Login from './routes/Login'
import { AnimatePresence } from 'framer-motion';
import Navbar from './components/Navbar'
import Template from './routes/Template';

const routes = [
  { path: '/', name: 'Home', Component: Home },
  { path: '/join', name: 'Join', Component: Join },
  { path: '/schedule', name: 'Schedule', Component: Schedule },
  { path: '/goals', name: 'Goals', Component: Goals },
  { path: '/live', name: 'Live', Component: Live },
  { path: '/login', name: 'Live', Component: Login }
]


export default function App() {

  const location = useLocation()
  
  return (
    <div className="App font-sans">
        <AnimatePresence exitBeforeEnter>
          <Routes key={location.pathname} location={location}>      
            <Route path="/" element={<Template />}>
                {
                  routes.map(({path, Component}, key) => 
                    <Route exact path={path} key={key} element={<Component />} />
                  )
                }
            </Route>
          </Routes>
        </AnimatePresence>
    </div>
  )
}

