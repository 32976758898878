import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import SubNavbar from './SubNavbar'
import { auth } from '../firebase'
import { onAuthStateChanged, signOut } from 'firebase/auth'

export default function Navbar() {

    const links = [
        {to: '/', label: 'HOME'},
        {to: '/join', label: 'HOW TO JOIN'},
        {to: '/schedule', label: 'SCHEDULE'},
        {to: '/goals', label: 'GOALS'},
        {to: '/live', label: 'LIVE'},
       // {to: '/login', label: 'LOGIN', button: true},
       // {to: '/#', label: 'LOGOUT', button: true, showUser: true}
    ]

    const [user, setUser] = useState({})

    onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser)
    })

    const [menuState, setMenuState] = useState("hidden")
    function handleMenuClick() {
        setMenuState(menuState.length > 0 ? "md:hidden" : "hidden")
    }

    function closeMenu() {
        setMenuState("hidden")
    }

    const logout = async () => {
        await signOut(auth)
    }

    return (
    <>
        <div className='fixed top-0 w-full z-40'>
            <div className='bg-mgpRed drop-shadow-md shadow-md'>
                <nav className='flex py-1 px-4 md:p-4 place-content-between font-semibold container mx-auto'>
                    <div>
                        <Link to="/"><div className='bg-contain bg-no-repeat bg-nav-logo bg-left w-60 h-10 md:w-60 md:h-16'></div></Link>
                    </div>
                    <div className='flex my-auto hidden md:inline'>
                        {links.map((link, idx) => (
                            <NavLink onClick={logout} to={link.to} key={idx} className={({isActive}) => "text-mgpWhite hover:text-mgpBlack p-2 " + (link.button ? "bg-mgpWhite text-mgpRed rounded " + (((user && link.showUser) || (!user && !link.showUser)) ? '' : 'hidden') : isActive ? " pointer-events-none text-mgpBlack underline underline-offset-2 decoration-4" : "")} >{link.label}</NavLink>
                        ))}
                    </div>
                    <div className='my-auto inline md:hidden'>
                        <button onClick={handleMenuClick}>
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
                        </button>
                    </div>
                </nav>
            </div>
            <SubNavbar />
        </div>
        <div className={menuState + ' w-6/12 h-full fixed top-0 right-0 text-mgpWhite bg-mgpDarkGrey p-4 z-50'}>
            <button className='text-2xl' onClick={closeMenu}>X</button>
            <div className='flex flex-col items-start'>
                {links.map((link, idx) => (
                    <div className='mt-4' key={idx}>
                        <NavLink to={link.to} onClick={closeMenu} key={idx} className={({isActive}) => "hover:text-mgpRed p-2 " + (link.button ? "bg-mgpWhite text-mgpRed rounded ml-2" : isActive ? " pointer-events-none text-mgpRed underline underline-offset-2 decoration-4" : " text-mgpWhite")} >{link.label}</NavLink>
                    </div>
                ))}
            </div>
        </div>
    </>
    )
}
