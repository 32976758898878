import { Link } from 'react-router-dom' 
import React, { Component } from 'react'
import { motion } from "framer-motion"
import AnimatedPage from '../components/AnimatedPage'

export class Home extends Component {

    componentDidMount() {
        document.title = "MARBLES GRAND PRIX"
    }

    render() {
        return (
            <AnimatedPage className='container mx-auto my-8'>
                <section className='text-center my-8'>
                    <div className='text-6xl font-black'>MARBLES</div>
                    <div className='text-5xl  font-black'>GRAND PRIX</div>
                </section>
                <section className='divide-y divide-mgpDarkGrey'>
                    <section className='my-8 container mx-auto'>
                        <div className="flex flex-col md:flex-row text-left p-4 gap-4">
                            <div className="basis-full lg:basis-1/2">
                                <div className='flex flex-col gap-4'>
                                    <div className='flex-row'>
                                        <div className="text-3xl font-extrabold text-mgpRed">WELCOME TO THE RACE</div>
                                        <p className='font-prose'>Welcome to <span className='text-mgpRed font-semibold'>Marbles Grand Prix</span>, the Twitch channel solely dedicated to streaming the popular racing simulator <a href="https://store.steampowered.com/app/1170970/Marbles_on_Stream/">Marbles On Stream</a>. Viewers join the contest and watch as dramatic marble races unfold in real-time - completely simulated without need for user interaction. You just sit back and cheer on your marble as it makes it way to the finish line!</p>
                                    </div>
                                    <div className='flex-row'>
                                        <div className="text-3xl font-extrabold text-mgpRed">JOIN UP, WIN PRIZES</div>
                                        <p className='font-prose'>What's a race without stakes? <span className='text-mgpRed font-semibold'>Marbles Grand Prix</span> offers a real-world prize to the winner of each session. And the entry fee to join the race? <em>Absolutely free</em>! Simply visit <a href="https://twitch.tv/MarblesGrandPrix">twitch.tv/MarblesGrandPrix</a> and enroll in the race when the stream goes live (follow <Link to="/join">these instructions</Link> if you need help). All you need is a free Twitch account.</p>
                                    </div>
                                    <div className='flex-row'>
                                        <div className="text-3xl font-extrabold text-mgpRed">GROW THE COMMUNITY</div>
                                        <p className='font-prose'>Wait, wouldn't more competition hurt your chances of winning the prize? That's true, so <span className="text-mgpRed font-semibold">Marbles Grand Prix</span> will setup stream goals to incentivize growth! Hitting these milestones will have various effects on the stream: expanding the prize pool, upping prize amounts, increasing the frequency of races, and more. Tell your friends!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="basis-full lg:basis-1/2 hidden lg:inline">
                                <div className='h-full rounded-lg bg-cover bg-hero-home justify-center flex flex-col text-8xl gap-4 pl-8 border-2 border-r-mgpRed border-b-mgpRed border-t-0 border-l-0'>
                                    <motion.div animate={{y:0, opacity: 1}} initial={{y: -50, opacity: 0}} transition={{duration: .35, delay: .35, ease: "easeOut"}} className={'font-black'}>WATCH</motion.div> 
                                    <motion.div animate={{y:0, opacity: 1}} initial={{y: -50, opacity: 0}} transition={{duration: .35, delay: .75, ease: "easeOut"}} className={'font-black'}>RACE</motion.div> 
                                    <motion.div animate={{y:0, opacity: 1}} initial={{y: -50, opacity: 0}} transition={{duration: .35, delay: 1.15, ease: "easeOut"}} className={'font-black text-mgpRed'}>WIN</motion.div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='my-8 mx-auto'>
                        <div className="flex flex-col md:flex-row container my-20 mx-auto gap-4">
                            <div className='flex flex-row md:flex-col w-full p-2 md:p-4 gap-4'>
                                <div className="flex flex-col w-8/12 md:w-full">
                                    <h1 className="text-2xl font-bold">NEED HELP?</h1>
                                    <p className='font-prose'>A bit lost? We've got some detailed instructions for you to follow! You'll be ready to race in no time.</p>
                                </div>
                                <div className="flex-initial flex-col my-auto w-4/12 md:w-full">
                                    <Link className='rounded-md bg-mgpRed p-2 mt-2 hover:text-mgpRed hover:bg-mgpWhite text-mgpWhite whitespace-nowrap' to="/join">LEARN MORE</Link>
                                </div>
                            </div>
                            <div className='flex flex-row md:flex-col w-full p-2 md:p-4 gap-4'>
                                <div className="flex flex-col w-8/12 md:w-full">
                                    <h1 className="text-2xl font-bold whitespace-nowrap">GET CONNECTED</h1>
                                    <p className='font-prose'>Stay up to date by following Marbles Grand Prix on Twitter or joining the official Discord channel.</p>
                                </div>
                                <div className="flex-initial flex-col my-auto w-4/12 md:w-full">
                                    <a href="https://twitter.com/marblesgp" target="_blank" className='rounded-md text-center bg-twitter p-2 mt-2 hover:text-twitter hover:bg-mgpWhite text-mgpWhite'>TWITTER</a>
                                    <br className='md:hidden'/>
                                    <br className='md:hidden'/>
                                    <a href="https://discord.io/marblesgrandprix" target="_blank" className='rounded-md text-center bg-discord p-2 mt-2 md:ml-2 hover:text-discord hover:bg-mgpWhite text-mgpWhite'>DISCORD</a>
                                    {/* <div className='flex flex-col md:flex-row ml-2 md:ml-0'>
                                        <div className='bg-mgpWhite shrink'>
                                            stuff in here
                                        </div>
                                        <a href="https://twitter.com/marblesgp" target="_blank" className='rounded-md text-center bg-twitter p-2 mt-2 hover:text-twitter hover:bg-mgpWhite text-mgpWhite'>TWITTER</a>
                                        <a href="https://discord.io/marblesgrandprix" target="_blank" className='rounded-md text-center bg-discord p-2 mt-2 md:ml-2 hover:text-discord hover:bg-mgpWhite text-mgpWhite'>DISCORD</a>
                                    </div> */}
                                </div>
                            </div>
                            <div className='flex flex-row md:flex-col w-full p-2 md:p-4 gap-4'>
                                <div className="flex flex-col w-8/12 md:w-full">
                                    <h1 className="text-2xl font-bold">CHECK THE GOALS</h1>
                                    <p className='font-prose'>The Grand Prix will grow and change along with the community. See the roadmap as we get more racers.</p>
                                </div>
                                <div className="flex-initial flex-col my-auto w-4/12 md:w-full">
                                    <Link className='rounded-md bg-mgpRed p-2 mt-2 hover:text-mgpRed hover:bg-mgpWhite text-mgpWhite whitespace-nowrap' to="/goals">LEARN MORE</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </AnimatedPage>
        )
    }
}

export default Home
