import React, {useEffect} from 'react'
import AnimatedPage from '../components/AnimatedPage'

export default function Schedule() {

    useEffect(() => {
        document.title = "MGP | SCHEDULE"
    })

    return (
        <AnimatedPage className='container mx-auto my-8'>
            <section className='text-center my-8'>
                <div className='text-6xl font-black'>COMING SOON</div>
            </section>
        </AnimatedPage>
    )
}
