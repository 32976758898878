import React, {useEffect, useState} from 'react'
import AnimatedPage from '../components/AnimatedPage'
import { auth } from '../firebase'
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth'

export default function Login() {
    
    useEffect(() => {
        document.title = "MGP | LOGIN"
    })

    const [loginEmail, setLoginEmail] = useState("")
    const [loginPassword, setLoginPassword] = useState("")

    /*
    const register = async () => {

    }
    */

    const [user, setUser] = useState({})

    const login = async () => {
        try {
            const user = await signInWithEmailAndPassword( auth, loginEmail, loginPassword )
            console.log(user)
        }catch(err) {
            console.log(err.message)
        }
    }

    onAuthStateChanged(auth, (user) => {
        setUser(user)
    })
  
    const logout = async () => {
        await signOut(auth)
    }

    return (
        <AnimatedPage className='container mx-auto my-8'>
            <section className='my-8 mx-auto text-center'>
                <div className={`${user ? 'text-3xl' : 'text-6xl'} font-black mb-10`}>{user ? `Logged in as ${user.email}` : 'Login'}</div>
                <form className={`w-full max-w-sm mx-auto ${user ? 'hidden' : ''}`}>
                    <div className="mb-4">
                        <label className="block text-mgpRed font-bold mb-2">
                            EMAIL
                        </label> 
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-mgpDarkGrey leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" required onChange={(event) => { setLoginEmail(event.target.value) }} />
                    </div>
                    <div className="mb-4">
                        <label className="block text-mgpRed font-bold mb-2">
                            PASSWORD
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-mgpDarkGrey leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" required onChange={(event) => { setLoginPassword(event.target.value) }}/>
                    </div>
                    <div className="md:flex md:items-center">
                        <div className="w-full">
                            <button className="shadow bg-mgpRed hover:bg-red-400 focus:shadow-outline focus:outline-none w-full text-mgpWhite font-bold py-2 px-4 rounded" type="button" onClick={login}>
                                LOGIN
                            </button>
                        </div>
                    </div>
                </form>
            </section>
        </AnimatedPage>
    )
}
