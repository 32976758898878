import React, {useEffect} from 'react'
import AnimatedPage from '../components/AnimatedPage'

export default function Join() {
    
    useEffect(() => {
        document.title = "MGP| JOIN"
    })

    return (
        <AnimatedPage>
            <div className='container mx-auto'>
                <section className='text-center py-8'>
                    <h1 className='text-4xl md:text-6xl font-black'>HOW TO JOIN</h1>
                </section>
                <section className='px-4 text-center md:text-left my-16'>
                    <h1 className='text-mgpRed text-xl md:text-3xl font-bold mb-8'>FOLLOW THE CHANNEL</h1>
                    <div className="flex flex-col items-center md:items-start text-lg gap-16">
                        <div className='flex flex-col md:flex-row container items-center'>
                            <div className='flex-row md:flex-col basis-full md:basis-1/2'> 
                                <div className="flex flex-col md:flex-row gap-2 items-center text-left">
                                    <div className="flex-row md:flex-col">
                                        <span className='bg-mgpRed px-4 py-2 rounded-full'>1</span>
                                    </div>
                                    <div className="flex-row md:flex-col">
                                        <p>Log in to your Twitch account or create one <a href='https://www.twitch.tv/signup'>here</a> if you do not have one</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row md:flex-col basis-full md:basis-1/2 items-end'>
                                <img src='/ttv_signup.png' className='rounded-md border-r-2 border-b-2 border-mgpRed' alt='signup at twitch.tv'></img>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row container items-center'>
                            <div className='flex-row md:flex-col basis-full md:basis-1/2'> 
                                <div className="flex flex-col md:flex-row gap-2 items-center text-left">
                                    <div className="flex-row md:flex-col">
                                        <span className='bg-mgpRed px-4 py-2 rounded-full'>2</span>
                                    </div>
                                    <div className="flex-row md:flex-col">
                                        <p>Search for the channel: <span className='text-mgpRed font-extrabold'>MarblesGrandPrix</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row md:flex-col basis-full md:basis-1/2 items-end'>
                                <img src='/ttv_search.png' className='rounded-md border-r-2 border-b-2 border-mgpRed' alt='search for MarblesGrandPrix'></img>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row container items-center'>
                            <div className='flex-row md:flex-col basis-full md:basis-1/2'> 
                                <div className="flex flex-col md:flex-row gap-2 items-center text-left">
                                    <div className="flex-row md:flex-col">
                                        <span className='bg-mgpRed px-4 py-2 rounded-full'>3</span>
                                    </div>
                                    <div className="flex-row md:flex-col">
                                        <p>Click the Follow button</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row md:flex-col basis-full md:basis-1/2 items-end'>
                                <img src='/ttv_follow.png' className='rounded-md border-r-2 border-b-2 border-mgpRed' alt='follow the channel'></img>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row container items-center'>
                            <div className='flex-row md:flex-col basis-full md:basis-1/2'> 
                                <div className="flex flex-col md:flex-row gap-2 items-center text-left">
                                    <div className="flex-row md:flex-col">
                                        <span className='bg-mgpRed px-4 py-2 rounded-full'>4</span>
                                    </div>
                                    <div className="flex-row md:flex-col">
                                        <p>Done! You're now eligible to join races when the Twitch channel goes live. Follow additonal instructions below to receive alerts when the races are about to start</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='px-4 text-center md:text-left my-32'>
                    <h1 className='text-mgpRed text-xl md:text-3xl font-bold mb-2'>CONFIGURE ALERTS (OPTIONAL)</h1>
                    <h2 className='text-mgpWhite text-lg md:text-xl font-semibold mb-8'>Use one of the options below to receive alerts when the channel goes live</h2>
                    <div className="flex flex-col items-center md:items-start text-lg gap-16">
                        <div className='flex flex-col md:flex-row container items-center'>
                            <div className='flex-row md:flex-col basis-full md:basis-1/2'> 
                                <div className="flex flex-col md:flex-row gap-2 items-center text-left">
                                    <div className="flex-row md:flex-col">
                                        <span className='bg-mgpRed px-4 py-2 rounded-full'>1</span>
                                    </div>
                                    <div className="flex-row md:flex-col">
                                        <p>Use the Twitch mobile app. Ensure that you are logged in, you are following MarblesGrandPrix and notifications are on.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row md:flex-col basis-full md:basis-1/2 items-end'>
                                <img src='/ttv_alert.png' className='rounded-md border-r-2 border-b-2 border-mgpRed' alt='twitch alerts'></img>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row container items-center'>
                            <div className='flex-row md:flex-col basis-full md:basis-1/2'> 
                                <div className="flex flex-col md:flex-row gap-2 items-center text-left">
                                    <div className="flex-row md:flex-col">
                                        <span className='bg-mgpRed px-4 py-2 rounded-full'>2</span>
                                    </div>
                                    <div className="flex-row md:flex-col">
                                        <p>Follow <a href='http://www.twitter.com/marblesgp'>@MarblesGP</a> on Twitter</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row container items-center'>
                            <div className='flex-row md:flex-col basis-full md:basis-1/2'> 
                                <div className="flex flex-col md:flex-row gap-2 items-center text-left">
                                    <div className="flex-row md:flex-col">
                                        <span className='bg-mgpRed px-4 py-2 rounded-full'>3</span>
                                    </div>
                                    <div className="flex-row md:flex-col">
                                        <p>Join the MarblesGrandPrix discord server: <a href="http://discord.io/MarblesGrandPrix">discord.io/MarblesGrandPrix</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </AnimatedPage>
    )
}
