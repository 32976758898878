import React, { Component } from 'react'

export default class SubNavbar extends Component {

    constructor() {
        super()

        this.state = {
            value: "",
            alerts: ["NEXT RACE: JUN 12 @ 12pm ET | $100 GP", "GOAL: 1,025 / 1,600 Followers | $400 GP", "GOAL: 105 / 200 Subscribers | $200 GP"],
            index: 0,
            showing: "",
            iid: -1,
            tid: -1
        }
    }

    componentDidMount() {
        if(this.state.alerts.length > 1) {
            let id = setInterval(this.rotate, 30000)
            this.setState({
                iid: id,
                showing: ""
            })
        }
        else if(this.state.alerts.length == 0) {
            this.setState({
                showing: "hidden"
            })
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.iid)
        clearInterval(this.state.tid)
    }

    rotate = () => {

        let id = setTimeout(this.increment.bind(this), 350)

        this.setState({
            value: "transition ease-out delay-150 duration-350 -translate-y-10",
            tid: id
        })

    }

    increment(e) {
        let idx = (this.state.index + 1) % this.state.alerts.length
        this.setState({
            value: "",
            index: idx
        })
    }

    getElement(offset) {
        if(offset >= this.state.alerts.length)  return ""
        return this.state.alerts[(this.state.index + offset) % this.state.alerts.length]
    }

    render() {
        return (
            <>
            <div className={this.state.showing + ' bg-mgpWhite flex place-content-center font-semibold text-mgpBlack text-sm md:text-xl p-2 pb-4 m-0 overflow-hidden'}>
                <div className='flex flex-col max-h-2 md:max-h-4 gap-3 p-0 m-0'>
                    <div className={this.state.value + " text-center"}>{this.getElement(0)}</div>
                    <div className={this.state.value + " text-center"}>{this.getElement(1)}</div>
                </div>
            </div>
            </>
        )
    }
}
